<template>
  <div>
    <van-row style="display: flex;justify-content: center;">
      <van-image
        width="183"
        height="128"
        :src="require('@/assets/activity/nhdllb/logo.png')"
      />
    </van-row>

    <van-row class="user_info">
      <span class="custom-title">{{ username }}</span>
      <van-button plain type="primary" size="small" @click="showLoginPopup" v-show="showLoginButton">登录</van-button>
      <van-button plain type="primary" size="small" @click="handleLogout" v-show="!showLoginButton">退出</van-button>
    </van-row>

    <van-popup
      class="login-popup"
      closeable
      close-icon="close"
      round
      v-model="showLogin"
    >
      <van-row class="login-title">
        <van-col span="24">玩家登录</van-col>
      </van-row>
      <van-form @submit="onSubmitLogin">
        <van-field
          v-model="account"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          clearable
          :rules="[{ required: true, message: '请填写用户名' },
          { pattern: account_pattern, message: '用户名长度 4-20 位' }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>

    <van-cell-group class="game-giftpack-group" v-for="(item, index) in data.list" :key="index">
      <van-cell>
            <van-image class="game-icon" width="56" height="56" v-show="item.external_name == '玛法灭世'" :src="require('@/assets/mfms-icon.png')" />
            <van-image class="game-icon" width="56" height="56" v-show="item.external_name == '笑傲沙城'" :src="require('@/assets/xasc-icon.png')" />
            <van-image class="game-icon" width="56" height="56" v-show="item.external_name == '怒火英豪'" :src="require('@/assets/nhyh-Icon-144.png')" />
            <div class="title game-title">
              <strong>{{ item.external_name }}</strong>
            </div>

      </van-cell>
      <van-cell :label="gift.game_prop_names" v-for="(gift, gift_index) in item.game_giftpacks" :key="gift_index">
          <template #title>
            <span class="title">{{ gift.show_giftpack_name }}</span>
            <van-tag type="success" plain v-for="(os, key) in gift.os" :key="key">{{ osList[os] }}</van-tag>
        </template>
          <template #right-icon>
            <van-button type="info" size="small" @click="handlePick(gift.id, gift_index, index)" v-show="!gift.cdkey">领取</van-button>
            <van-button type="info" size="small" @click="handleShowCdkey(gift.cdkey)" v-show="gift.cdkey">查看</van-button>
          </template>
      </van-cell>
      <van-cell v-if="item.count > 3" @click="handleGetMore(item.id, index, $event)">
        <p class="more">查看更多礼包({{ item.count - 3 }})<van-icon name="arrow-down" /></p>
      </van-cell>
    </van-cell-group>

    <!-- <van-cell-group class="game-giftpack-group">
      <van-cell>
        <div>
            <van-image width="56" height="56" src="https://img.yzcdn.cn/vant/cat.jpeg" />王者传奇
        </div>
      </van-cell>
      <van-cell label="元宝*100;金币*200">
          <template #title>
            <span class="custom-title">新手礼包</span>
            <van-tag type="success" plain>标签</van-tag>
        </template>
          <template #right-icon>
            <van-button type="info">领取</van-button>
        </template>
      </van-cell>
    </van-cell-group> -->
    <van-dialog v-model="dialogCdkeyShow" title="兑换码" confirm-button-text="复制" :message="dialog_cdkey" :before-close="beforeClose">
      <!-- <span class="dialog_content"><strong>{{ dialog_cdkey }}</strong></span> -->
    </van-dialog>

  </div>
</template>

<script>

import { gameGiftpackSimple, gameGiftpackList, login, pick } from "@/api/gameGiftpack"
import { logout } from "@/api/login"
import { Toast } from 'vant';
import { getToken, getUsername, setUsername, removeUsername, removeToken } from '@/utils/auth';

// import Clipboard from 'clipboard'

export default {
  data() {
    return {
      username: '游戏玩家',
      showLoginButton: true,
      showLogin: false,
      data: {
        list: []
      },
      hashtime: new Date().getTime(),
      account: undefined,
      password: undefined,
      account_pattern: /^\w{4,20}$/,
      dialogCdkeyShow: false,
      dialog_cdkey: undefined,
      osList: {ios: '苹果', android: '安卓'}
    };
  },
  created() {
    this.getSimple(),
    this.loginState()
  },
  methods: {
    loginState() {
      if (getToken()) {
        this.username = getUsername()
        this.showLoginButton = false
      }
    },
    // 查看更多
    handleGetMore(game_id, index) {
      let params = {'game_id': game_id}
      if (getToken()) {
        params['account'] = getUsername()
      }
      gameGiftpackList(params).then(response => {
        this.data.list[index]['game_giftpacks'] = response
        this.data.list[index]['count'] = false
        console.log(response)
      }).catch(() => {
      })
    },
    getSimple() {
      let params = {}
      if (getToken()) {
        params = {account: getUsername()}
      }
      gameGiftpackSimple(params).then(response => {
        this.data.list = response
      }).catch(() => {

      })
    },
    showLoginPopup() {
      this.showLogin = true
    },
    // 登录
    onSubmitLogin() {
      // const that = this
      Toast.loading({
          message: '登录中，请稍等!',
          forbidClick: true
      })
      login({username: this.account, password: this.password}).then(response => {
        console.log(response)
        // this.showLogin = false
        // this.showLoginButton = false
        // this.username = response.account
        setUsername(response.account)
        // this.$router.go(0)
        location.reload()
      }).catch(() => {

      })
    },
    // 领取
    handlePick(id, gift_index, index) {
      if (!getToken()) {
        this.showLogin = true
      } else {
        pick({id: id}).then(response => {
          this.dialog_cdkey = response.cdkey
          this.dialogCdkeyShow = true
          // 按钮切换为查看
          this.data.list[index]['game_giftpacks'][gift_index]['cdkey'] = response.cdkey
        }).catch(() => {})
      }
    },
    // 查看
    handleShowCdkey(cdkey) {
      this.dialogCdkeyShow = true
      this.dialog_cdkey = cdkey
    },
    beforeClose(action, done) {
      console.log(action)
      if (action === 'confirm') {
        var oInput = document.createElement('input'); //创建一个隐藏input（重要!）
        oInput.value = this.dialog_cdkey;    //赋值
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display = 'none';
        console.log('复制成功')
      }
      done()
    },
    handleLogout() {
      logout().then(response => {
        console.log(response)
        removeToken()
        removeUsername()
        // this.$router.go(0)
        location.reload()
      }).catch(() => {

      })
    }
  },

};
</script>

<style >

html,
body {
  height: 100%;
}
body {
  background: #f5f5fa;
  font-size: 14px;
}
.user_info {
  height: 62px;
  /* padding: 10px 0; */
  background: #fff;
}
/* .user-info > div:nth-child(1) div i:before {
  font-size: 0.24rem;
} */
.game-giftpack-group {
  margin: 15px 0;
}
.title {
  font-size: 16px;
  color: #333;
}
.game-title {
  float: left;
  margin-left: 10px;
  line-height: 56px;
}
.game-icon {
  float: left;
}
.more {
  color: #999;
  font-size: 14px;
  text-align: center;
  margin: 4px 0;
  /* line-height: 1; */
  /* padding: 15px 0; */
  /* border-top: .01rem solid #f1f1f6; */
}
.van-tag {
  margin-left: 5px;
}
.login-popup {
  width: 88%;
}
.login-title {
  height: 65px;
  text-align: center;
  line-height: 65px;
  font-size: 20px;
}
.van-dialog__content {
    text-align: center;
    margin: 18px;
}
</style>
